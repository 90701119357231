import React, { FC } from 'react';
import cl from './Header.module.scss'
import logo from '../../assets/logo.png'
import signInIcon from '../../assets/sign-in-btn.png'

const Header: FC = () => {
    return (
        <header className={cl.header}>
            <div className={cl.logoWrap}>
                <img src={logo} alt="Logo" className={cl.logo}/>

                <p className={cl.logoText}>
                    Разработка <br />
                    высоконагруженых IT решений
                </p>
            </div>

            <button className={cl.signInBtn}>
                <img src={signInIcon} alt="sign-in"/>
                Войти в систему
            </button>

            {/*<div className={cl.languageWrap}>*/}
            {/*    <button className={cl.languageMainBtn}>Eng</button>*/}
            {/*    <button className={cl.languageSecondaryBtn}>Ru</button>*/}
            {/*</div>*/}

            <div className={cl.contacts}>
                <a className={cl.phone} href="tel:88005507984">8 (800) 550-79-84</a>
                {/*<p className={cl.schedule}> Ежедневно c 09 - 20</p>*/}
                <a href="mailto:info@itbump.ru" className={cl.schedule}>info@itbump.ru</a>
            </div>

        </header>
    );
};

export default Header;
