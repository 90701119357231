import React from 'react';
import cl from './App.module.css';
import Header from './components/Header/Header';
import Navigation from './components/Navigation/Navigation';
import Main from './components/Main/Main';
import Bullets from './components/Bullets/Bullets';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CommunicationForm from './components/CommunicationForm/CommunicationForm';

function App() {
  return (
      <BrowserRouter>
        <div className={cl.container}>
            <div>
                <Header />

                <Navigation />

                <Main />
            </div>

            <Bullets />

            <Routes>
                <Route path='/request' element={<CommunicationForm />} />
            </Routes>
        </div>
      </BrowserRouter>
  );
}

export default App;
