import React, { FC } from 'react';
import cl from './Main.module.scss'
import stadium from '../../assets/stadium.png'
import playBtnPicture from '../../assets/play-btn.png'
import { Link } from 'react-router-dom';

const Main: FC = () => {

    return (
        <main>
            <section className={cl.main}>
                <div className={cl.info}>
                    <div>
                        <h1 className={cl.mainHeader}>
                            Упростите  <span className={cl.greenBg} >ведение статистики </span>
                            с помощью системы <span className={cl.pinkColor} >НАГРАДИОН</span>
                        </h1>
                        <img src={stadium} alt="stadium" className={cl.mobileOnly} />
                    </div>

                    <div className={cl.actions}>
                        <div className={cl.accessBtnWrap}>
                            <Link to='/request'>
                                <button className={cl.getAccessBtn}>
                                    Обсудить проект с <br />
                                    командой разработки
                                </button>
                            </Link>
                            <p className={cl.accessInfo}>
                                Предложим несколько вариантов сотрудничества и осметим проект
                            </p>
                        </div>

                        <div className={cl.videoBtnWrap}>
                            <img src={playBtnPicture} alt="play" className={cl.videoPlayBtn}/>
                            <p className={cl.videoInfo}>
                                <span>Посмотрите 2 минуты</span> <br />
                                видео, чтобы узнать <br />
                                как работает система <br />
                            </p>
                        </div>

                    </div>
                </div>

                <img src={stadium} alt="stadium" className={cl.mainImage} />

            </section>
        </main>
    );
};

export default Main;
