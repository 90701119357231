import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const normalizePhoneNumber = (phone: string) => {
    const phoneNumber = parsePhoneNumberFromString(phone)
    if(!phoneNumber){
        return phone
    }

    return (
        phoneNumber.formatInternational()
    );
};
