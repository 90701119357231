import React from 'react';
import cl from './CommunicationForm.module.scss';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { normalizePhoneNumber } from '../../utilities/phone-normalizer';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

interface CommunicationFormFields {
    name: string;
    phone: string;
    email: string;
}

const schema = yup.object().shape({
    name: yup.string().required('Это обязательное поле').min(2, 'Имя слишком короткое'),
    phone: yup.string().required('Это обязательное поле'),
    email: yup.string().email('Некорректный email').required('Это обязательное поле'),
})

// TODO: Выкинуть, оно не работает
const sendEmail = async () => {
    await fetch('https://api.mailjet.com/v3.1/send', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            user: '0cfcf2126292969c47a1a8ddbaeb7232:c169f49537393e15ccb23fdaf40464dc',
        },
        body: JSON.stringify({
            Messages: [
                {
                    "From": {
                        "Email": "",
                        "Name": ""
                    },
                    "To": [
                        {
                            "Email": '',
                            "Name": ""
                        }
                    ],
                    "Subject": "",
                    "TextPart": "",
                    "HTMLPart":
                        `
                                <div>

                                </div>
                            `,
                    "CustomID": ""
                }
            ]
        })
    })
}

const CommunicationForm = () => {
    const {register, handleSubmit, formState: { errors }} = useForm<CommunicationFormFields>({
        mode: 'onBlur',
        resolver: yupResolver(schema)
    })

    const onSubmit = async (data: CommunicationFormFields) => {
        // Implement form submit logic here
        await sendEmail()
        console.log(data);
    }

    return (
        <div className={cl.popup}>

            <Link to='/' className={cl.closeBtn}>
                <CloseIcon />
            </Link>

            <form className={cl.form} onSubmit={handleSubmit(onSubmit)}>
                <legend>Оставьте заявку</legend>

                <p className={cl.subLegend}>
                    И наш менеджер свяжется с вами <br />
                    в ближайшее время
                </p>

                <fieldset>
                    <TextField
                        label="Ваше имя"
                        variant="standard"
                        fullWidth
                        className={cl.input}
                        margin='none'
                        {...register('name')}
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        autoComplete='name'
                    />
                    <TextField
                        type='tel'
                        label="Контактный телефон"
                        variant="standard"
                        fullWidth
                        margin='none'
                        className={cl.input}
                        {...register('phone')}
                        error={!!errors.phone}
                        helperText={errors?.phone?.message}
                        autoComplete='phone'
                        onChange={(event) => {
                            event.target.value = normalizePhoneNumber(event.target.value);
                        }}
                    />
                    <TextField
                        label="Email"
                        variant="standard"
                        fullWidth
                        margin='none'
                        className={cl.input}
                        {...register('email')}
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        autoComplete='email'
                    />
                </fieldset>

                <button className={'primary-btn ' + cl.submitBtn} >
                    Связаться с командой
                </button>

            </form>
        </div>
    );
};

export default CommunicationForm;
