import React, { FC, useState } from 'react';
// @ts-ignore
import AnimatedNumber from "animated-number-react";
import cl from './Bullets.module.scss'

const Bullets: FC = () => {

    return (
        <section className={cl.bulletsWrap}>
            <div className={cl.bullet}>
                <h5 className={cl.bulletHeader}>5<span>0+</span></h5>
                <p className={cl.bulletText}>
                    Федераций России <br />
                    на нашей платформе
                </p>
            </div>

            <div className={cl.bullet}>
                <h5 className={cl.bulletHeader}>10 <span>000+</span></h5>
                <p className={cl.bulletText}>
                    проведенных турниров <br />
                    по всей России
                </p>
            </div>

            <div className={cl.bullet}>
                <h5 className={cl.bulletHeader}>1<span>00+</span></h5>
                <p className={cl.bulletText}>
                    организаций <br />
                    используют систему
                </p>
            </div>

            <div className={cl.bullet}>
                <h5 className={cl.bulletHeader}>16 <span>млн+</span></h5>
                <p className={cl.bulletText}>
                    посетителей наших <br />
                    сайтов статистики
                </p>
            </div>
        </section>
    );
};

export default Bullets;
