import React, { FC } from 'react';
import cl from './Navigation.module.scss'

const Navigation: FC = () => {
    return (
        <nav className={cl.navigation}>
            <menu className={cl.menu}>
                <li>Главная</li>
                <li>О компании</li>
                <li>Наши услуги</li>
                <li>Наши работы</li>
                <li>Новости</li>
                <li>Контакты</li>
            </menu>

            <hr className={cl.line}/>
        </nav>
    );
};

export default Navigation;
